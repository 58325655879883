<template>
  <div>
    <img src="../assets/logo-ttb.svg" class="mt-4" alt="logo-ttb" />
    <div class="box">
      <div class="mb-3">
        <img src="/assets/icon.png" class="icon-img" alt="network-error" />
      </div>
      <div class="title mb-2 mb-sm-3">ลิงก์หมดอายุ</div>
      <div class="mb-3">
        ลิงก์ให้คะแนนความพึงพอใจของคุณสิ้นสุดระยะเวลาการประเมินแล้ว
      </div>
      <div class="mb-3">ขอขอบคุณที่ให้ทีทีบีได้ดูแล</div>
    </div>
  </div>
</template>

<script>
export default {}
</script>

<style scoped>
div {
  color: #002d63;
}

.box {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 70%;
}

.icon-img {
  width: 50%;
  min-width: 180px;
  max-width: 300px;
}

.title {
  font-size: 18px;
  font-weight: 700;
}
</style>
